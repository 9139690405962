import React, { useState,useEffect}  from "react"

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
export default function FiguresFront() {
      const [isRed, setIsRed] = useState([true,true,true,true,true,true,true,true,true]);
         const [ details,setDetails] = useState({slideImage:[],dataisLoaded:0});
         const [ details2,setDetails2] = useState({slideImage2:[],dataisLoaded2:0});
         useEffect(() =>{
                 fetch(
                       process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=FrontLinks.LinkIconRed`,
                       {
                         method: 'GET',
                         headers: {
                           Authorization: process.env.REACT_APP_API_KEY,
                           Accept: 'application/json'
                         },
                         credentials: "include",
                       }
                   )
                   .then((res) => res.json())
                   .then((json) => {
                     setDetails({
                         ...details,slideImage:json,dataisLoaded:1
                     });
                   })
                   .catch((error) => {
                     setDetails({
                       ...details,dataisLoaded:-1
                   });
                   });

              fetch(
                    process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=FrontLinks.LinkIconWhite`,
                    {
                      method: 'GET',
                      headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                        Accept: 'application/json'
                      },
                      credentials: "include",
                    }
                )
                .then((res) => res.json())
                .then((json) => {
                  setDetails2({
                      ...details2,slideImage2:json,dataisLoaded2:1
                  });
                })
                .catch((error) => {
                  setDetails2({
                    ...details2,dataisLoaded2:-1
                });
                });


           }, []);
      
           if (details.dataisLoaded===0) return(
             <Box 
             display="flex"
             justifyContent="center"
             alignItems="center"
             minHeight="60vh"
             >
               <CircularProgress />
             </Box>
           )
           else if (details.dataisLoaded===-1)  return(
             <Box 
             display="flex"
             justifyContent="center"
             alignItems="center"
             minHeight="60vh"
             >
               <Alert severity="error">
               <AlertTitle>Connection Error</AlertTitle>
                    Could not connect to Server, kindly refresh
               </Alert>
             </Box>
           )
       
           else return(
         <>
         <ul className="important-links-list">
            {details.slideImage.data.attributes.FrontLinks.map((mini,index)=>{
               return(
                        <li key={mini.id} className="important-links"  
                           onMouseEnter={() => setIsRed(prevState => prevState.map((item, idx) => idx === mini.id-1 ? !item : item))}
                           onMouseLeave={() => setIsRed(prevState => prevState.map((item, idx) => idx === mini.id-1 ? !item : item))}>
                           <a href={mini.LinkUrl} rel="noopener noreferrer" target="_blank">
                              <div className="icon-text">
                                 <h4>{mini.LinkText}</h4>
                              </div>
                              <div className="implink_aftera" style={{backgroundImage: `url(${isRed[mini.id-1]?process.env.REACT_APP_BACK_SERVER+details.slideImage.data.attributes.FrontLinks[index].LinkIconRed.data.attributes.url:process.env.REACT_APP_BACK_SERVER+details2.slideImage2.data.attributes.FrontLinks[index].LinkIconWhite.data.attributes.url})`}}></div>
                           </a>
                        </li>
               )})
         }
         </ul>
      </>
  
      )
}

