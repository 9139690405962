import React, { useState,useEffect}  from "react"
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function Gallery(){
  const navigate = useNavigate();
  
  const [ details,setDetails] = useState({galleryImages:[],dataisLoaded:0});
  
  useEffect(() =>{
          fetch(
                process.env.REACT_APP_BACK_SERVER+`/api/gallery-images?sort[0]=publishedAt%3Adesc&populate=Image`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                    Accept: 'application/json'
                  },
                  credentials: "include",
                }
            )
            .then((res) => res.json())
            .then((json) => {
              setDetails({
                  ...details,galleryImages:json,dataisLoaded:1
              });
            })
            .catch((error) => {
              setDetails({
                ...details,dataisLoaded:-1
            });
            });
    }, []);
   
    if (details.dataisLoaded===0) return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )
    else if (details.dataisLoaded===-1)  return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <Alert severity="error">
        <AlertTitle>Connection Error</AlertTitle>
             Could not connect to Server, kindly refresh
        </Alert>
      </Box>
    )

    else return(
      
        <Box sx={{ width: 1040, margin:"50px auto",
        textAlign:"center"
        }}>
        <Typography  mb={3} variant="h4" component="h1">Gallery</Typography>

        <ImageList cols={3} gap={10}>
        {details.galleryImages.data.map((item, index) => (
            <ImageListItem key={item.id} onClick={() => {navigate('/gallery/'+item.id)}}>
              <img
                src={process.env.REACT_APP_BACK_SERVER+`${item.attributes.Image.data[0].attributes.formats.small.url}?w=248&fit=crop&auto=format`}
                srcSet={process.env.REACT_APP_BACK_SERVER+`${item.attributes.Image.data[0].attributes.formats.medium.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.attributes.GalleryName}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.attributes.GalleryName}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>

      </Box>
    ) 
}


