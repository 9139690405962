import React, { useState,useEffect}  from "react"
import Typography from '@mui/material/Typography';
import { Paper,Divider,Box, Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



export default function RTI(){
  const [ details,setDetails] = useState({page:[],dataisLoaded:0});
  let pag=[];
  useEffect(() =>{
          fetch(
              process.env.REACT_APP_BACK_SERVER+`/api/rti`,     
                {
                  method: 'GET',
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                    Accept: 'application/json'
                  },
                  credentials: "include",
                }
            )
            .then((res) => res.json())
            .then((json) => {
              setDetails({
                  ...details,page:json,dataisLoaded:1
              });
            })
            .catch((error) => {
              setDetails({
                ...details,dataisLoaded:-1
            });
            });
          
    }, []);

    if (details.dataisLoaded===0) return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )
    else if (details.dataisLoaded===-1)  return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <Alert severity="error">
        <AlertTitle>Connection Error</AlertTitle>
             Could not connect to Server, kindly refresh
        </Alert>
      </Box>
    )

    else{pag =details.page.data.attributes;
     return(
      <section id="fontSize" className="wrapper body-wrapper ">
      <Container>
        <Paper elevation={3} sx={{margin: 5, padding:4 }}>
   
        {console.log(pag.body)}
          <Box>
          <Typography m={4} variant="h5" component="h1">{pag.title}</Typography>
          <Divider variant="middle" />
            <div dangerouslySetInnerHTML={{__html:pag.body}}></div>
          </Box>      
        </Paper>
      </Container>
      </section>
    )} 
}


