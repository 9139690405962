import React, { useState,useEffect}  from "react"
import {useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; 
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';



export default function GallerySubPage(){
  let { id } = useParams();
  let galleryimg=[],galleryimg1=[];
  const [lightboxController, setLightboxController] = useState({
		isOpen: false,
		photoIndex: 1
	});
  const [ details,setDetails] = useState({galleryImages:[],dataisLoaded:0});

	function openLightboxOnSlide(number) {
		setLightboxController({
			isOpen: !lightboxController.isOpen,
			photoIndex: number-1
		});
	}

  
  
  useEffect(() =>{
          fetch(
                process.env.REACT_APP_BACK_SERVER+`/api/gallery-images?sort[0]=publishedAt%3Adesc&populate=Image`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                    Accept: 'application/json'
                  },
                  credentials: "include",
                }
            )
            .then((res) => res.json())
            .then((json) => {
              setDetails({
                  ...details,galleryImages:json,dataisLoaded:1
              });
            })
            .catch((error) => {
              setDetails({
                ...details,dataisLoaded:-1
            });
            });
    }, []);
   
    
    if (details.dataisLoaded===0) return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )
    else if (details.dataisLoaded===-1)  return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <Alert severity="error">
        <AlertTitle>Connection Error</AlertTitle>
             Could not connect to Server, kindly refresh
        </Alert>
      </Box>
    )
    else {
      galleryimg1=details.galleryImages.data.filter(function(data){ return data.id == id })
      galleryimg=galleryimg1[0].attributes.Image.data
      return(
        
        <Box sx={{ width: 1040, margin:"50px auto",
        textAlign:"center"
        }}> 
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/gallery">
            Gallery
          </Link>
          <Typography color="text.primary">{galleryimg1[0].attributes.GalleryName}</Typography>
        </Breadcrumbs>

        <Typography  mb={3} variant="h4" component="h1">{galleryimg1[0].attributes.GalleryName}</Typography>
        <ImageList variant="masonry" cols={3} gap={10}>
          {galleryimg.map((item,index) => (
            <ImageListItem key={item.id} onClick={() => openLightboxOnSlide(index+1)}>
            
                <img
                  src={process.env.REACT_APP_BACK_SERVER+`${item.attributes.formats.small.url}?w=248&fit=crop&auto=format`}
                  srcSet={process.env.REACT_APP_BACK_SERVER+`${item.attributes.formats.medium.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.attributes.alternativeText}
                  loading="lazy"
                />
            </ImageListItem>
          ))}
        </ImageList>
        {
        lightboxController.isOpen && (
          
          <Lightbox
            mainSrc={process.env.REACT_APP_BACK_SERVER+galleryimg[lightboxController.photoIndex].attributes.url}
            nextSrc={process.env.REACT_APP_BACK_SERVER+process.env.REACT_APP_BACK_SERVER+galleryimg[(lightboxController.photoIndex + 1) % galleryimg.length].attributes.url}
            prevSrc={process.env.REACT_APP_BACK_SERVER+galleryimg[(lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length].attributes.url}
            onCloseRequest={() => setLightboxController({isOpen: false,photoIndex: 0})}
            onMovePrevRequest={() =>
              setLightboxController({isOpen: true,photoIndex: (lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length})
            }
            onMoveNextRequest={() =>
              setLightboxController({isOpen: true,photoIndex: (lightboxController.photoIndex + 1) % galleryimg.length})
            }
            imageCaption={<h2>{galleryimg[lightboxController.photoIndex].attributes.alternativeText}</h2>}
            mainSrcThumbnail={process.env.REACT_APP_BACK_SERVER+galleryimg[lightboxController.photoIndex].attributes.formats.thumbnail.url}
            nextSrcThumbnail={process.env.REACT_APP_BACK_SERVER+galleryimg[(lightboxController.photoIndex + 1) % galleryimg.length].attributes.formats.thumbnail.url}
            prevSrcThumbnail={process.env.REACT_APP_BACK_SERVER+galleryimg[(lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length].attributes.formats.thumbnail.url}
           
           />
        )}
      </Box>
    ) 
  }
}


