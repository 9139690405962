import React, { useState,useEffect}  from "react"
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; 

export default function MainGallery() {
   const [lightboxController, setLightboxController] = useState({
		isOpen: false,
		photoIndex: 1
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			isOpen: !lightboxController.isOpen,
			photoIndex: number-1
		});
	}
  

   const [ details,setDetails] = useState({slideImage:[],dataisLoaded:0});
  
   useEffect(() =>{
           fetch(
                 process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=*`,
                 {
                   method: 'GET',
                   headers: {
                     Authorization: process.env.REACT_APP_API_KEY,
                     Accept: 'application/json'
                   },
                   credentials: "include",
                 }
             )
             .then((res) => res.json())
             .then((json) => {
               setDetails({
                   ...details,slideImage:json,dataisLoaded:1
               });
             })
             .catch((error) => {
               setDetails({
                 ...details,dataisLoaded:-1
             });
             });
     }, []);

     if (details.dataisLoaded===1) {
      const galleryimg=details.slideImage.data.attributes.images.data
         const galleryImagesEle=details.slideImage.data.attributes.images.data.map((img,index)=>{return(
         <li key={img.id}> 
           <a href={process.env.REACT_APP_BACK_SERVER+img.attributes.url} data-fancybox="images" onClick={(e)=>{ e.preventDefault();openLightboxOnSlide(index+1)}}>
               <img className="slice"  src={process.env.REACT_APP_BACK_SERVER+img.attributes.formats.thumbnail.url}  alt= {img.attributes.alternativeText} />
            </a>         
         </li>)})

      const vid=details.slideImage.data.attributes.video.data;
  
      const galleryVediosEle=<video key={vid.id} controls="controls" preload="metadata"><source src={process.env.REACT_APP_BACK_SERVER+vid.attributes.url} type="video/mp4"></source></video>
      
      return(
         <div className="wrapper home-btm-slider">
         <div className="container common-container four_content gallery-container">
            <div className="gallery-area clearfix">
               <div className="gallery-heading">
                  <h3>Photo Gallery</h3>
                 {/*<!-- <a className="bttn-more bttn-view" href="#" alt="View all" title="View All About Photo Gallery"><span>View All</span></a> --> */}
               </div>
               <div className="fancybox">
                  <ul className="slider">
                    {galleryImagesEle}
                  
                  </ul>
               </div>
            </div>
            <div className="gallery-right">
               <div className="video-heading">
                  <h3>Video Gallery</h3>
                {/* <!--   <a className="bttn-more bttn-view" href="#" alt="View all" title="View All About video"><span>View All</span></a> -->*/}
               </div>
               <div className="video-wrapper">
                  {galleryVediosEle}
               </div>
            </div>
         </div>
         {lightboxController.isOpen && (
          <Lightbox 
            mainSrc={process.env.REACT_APP_BACK_SERVER+galleryimg[lightboxController.photoIndex].attributes.url}
            nextSrc={galleryimg[(lightboxController.photoIndex + 1) % galleryimg.length].attributes.url}
            prevSrc={galleryimg[(lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length].attributes.url}
            onCloseRequest={() => setLightboxController({isOpen: false,photoIndex: 0})}
            onMovePrevRequest={() =>
              setLightboxController({isOpen: true,photoIndex: (lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length})
            }
            onMoveNextRequest={() =>
              setLightboxController({isOpen: true,photoIndex: (lightboxController.photoIndex + 1) % galleryimg.length})
            }
            imageCaption={<h2>{galleryimg[lightboxController.photoIndex].alternativeText}</h2>}
            mainSrcThumbnail={galleryimg[lightboxController.photoIndex].attributes.formats.thumbnail.url}
            nextSrcThumbnail={galleryimg[(lightboxController.photoIndex + 1) % galleryimg.length].attributes.formats.thumbnail.url}
            prevSrcThumbnail={galleryimg[(lightboxController.photoIndex + galleryimg.length - 1) % galleryimg.length].attributes.formats.thumbnail.url}
           
          />
        )}
      </div>
      
      )
}
}

