
import { useEffect } from 'react';






const ScriptComponent = ({setLoaded})=>{
  function setScript(url,callback){
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", url);
    head.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }

  useEffect(() => {
    setScript( "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js", ()=>{
      setScript( "https://code.jquery.com/jquery-migrate-3.4.0.min.js", ()=>{
        setScript( "/assets/js/jquery-accessibleMegaMenu.js", ()=>{
          setScript( "/assets/js/framework.js", ()=>{
            setScript( "/assets/js/jquery.flexslider.js", ()=>{
              setScript( "/assets/js/font-size.js", ()=>{    
                setScript( "/assets/js/swithcer.js", ()=>{    
                  setScript( "/theme/js/ma5gallery.js", ()=>{    
                    setScript( "/assets/js/megamenu.js", ()=>{    
                      setScript( "/theme/js/easyResponsiveTabs.js", ()=>{    
                        setScript( "/theme/js/custom.js",setLoaded(true)
                        )
                      });      
                    });      
                  });      
                });      
              });      
          });
        });
      });
    });
  });
  }, []);

  }

  export default ScriptComponent