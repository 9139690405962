import React, { useState,useEffect}  from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function Notices() {
      const [ details1,setDetails1] = useState({notificationsSROs:[],dataisLoaded:0});
      const [ details2,setDetails2] = useState({circcularsOrders:[],dataisLoaded:0});
      const [ details3,setDetails3] = useState({tenders:[],dataisLoaded:0});
      useEffect(() =>{
              fetch(
                    process.env.REACT_APP_BACK_SERVER+`/api/documents/?filters[document_types][name][$eq][0]=Notification&filters[document_types][name][$eq][1]=SRO&sort[0]=publishedAt%3Adesc&populate=file`,
                    {
                      method: 'GET',
                      headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                        Accept: 'application/json'
                      },
                      credentials: "include",
                    }
                )
                .then((res) => res.json())
                .then((json) => {
                  setDetails1({
                      ...details1,notificationsSROs:json,dataisLoaded:1
                  });
                })
                .catch((error) => {
                  setDetails1({
                    ...details1,dataisLoaded:-1
                });
                });
        }, []);
    
        useEffect(() =>{
         fetch(
               process.env.REACT_APP_BACK_SERVER+`/api/documents/?filters[document_types][name][$eq][0]=Order&filters[document_types][name][$eq][1]=Circular&sort[0]=publishedAt%3Adesc&populate=file`,
               {
                 method: 'GET',
                 headers: {
                   Authorization: process.env.REACT_APP_API_KEY,
                   Accept: 'application/json'
                 },
                 credentials: "include",
               }
           )
           .then((res) => res.json())
           .then((json) => {
             setDetails2({
                 ...details2,circcularsOrders:json,dataisLoaded:1
             });
           })
           .catch((error) => {
             setDetails2({
               ...details2,dataisLoaded:-1
           });
           });
   }, []);

   useEffect(() =>{
      fetch(
            process.env.REACT_APP_BACK_SERVER+`/api/documents/?filters[document_types][name][$eq]=Tender&sort[0]=publishedAt%3Adesc&populate=file`,
            {
              method: 'GET',
              headers: {
                Authorization: process.env.REACT_APP_API_KEY,
                Accept: 'application/json'
              },
              credentials: "include",
            }
        )
        .then((res) => res.json())
        .then((json) => {
          setDetails3({
              ...details3,tenders:json,dataisLoaded:1
          });
        })
        .catch((error) => {
          setDetails3({
            ...details3,dataisLoaded:-1
        });
        });
}, []);

        if (details1.dataisLoaded===0 || details2.dataisLoaded===0 || details3.dataisLoaded===0 ) return(
          <Box 
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
          >
            <CircularProgress />
          </Box>
        )
        else if (details1.dataisLoaded===-1 || details2.dataisLoaded===-1 || details3.dataisLoaded===-1 )  return(
          <Box 
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
          >
            <Alert severity="error">
            <AlertTitle>Connection Error</AlertTitle>
                 Could not connect to Server, kindly refresh
            </Alert>
          </Box>
        )
    
        else {

         const notices=details1.notificationsSROs.data.map((notice)=><li key={notice.id}><a href={process.env.REACT_APP_BACK_SERVER+notice.attributes.file.data.attributes.url}><i className="fa fa-file-pdf-o" aria-hidden="true"></i>{notice.attributes.title}</a></li>)
         const circularsOrdersele=details2.circcularsOrders.data.map((cir)=><li key={cir.id}><a href={process.env.REACT_APP_BACK_SERVER+cir.attributes.file.data.attributes.url}><i className="fa fa-file-pdf-o" aria-hidden="true"></i>{cir.attributes.title}</a></li>)
         const tendersele=details3.tenders.data.map((ten)=><li key={ten.id}><a href={process.env.REACT_APP_BACK_SERVER+ten.attributes.file.data.attributes.url}><i className="fa fa-file-pdf-o" aria-hidden="true"></i>{ten.attributes.title}</a></li>)
        
         return(
         <div className="wrapper tab-section">
         <div className="container common-container four_content">
            <div className="page-tab clearfix">
               <div className="page-tab-res clearfix">
                  <div id="parentHorizontalTab">
                     <ul className="resp-tabs-list hor_1">
                        <li><a href="#1">SROs &#47; Notifications</a></li>
                        <li><a href="#2">Circulars &#47; Orders</a></li>
                        <li><a href="#3">Tenders</a></li>
                     </ul>
                     <div className="resp-tabs-container hor_1">
                        <div className="clearfix" id="hor_1_tab_item-0">
                           <ul className="content_list">
                              {notices}
                           </ul>
                        </div>
                        <div className="clearfix" id="hor_1_tab_item-1">
                           <ul className="content_list">
                            {circularsOrdersele}
                           </ul>
                        </div>
                        <div className="clearfix" id="hor_1_tab_item-2">
                           <ul className="content_list">
                             {tendersele}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      )
         }
}
