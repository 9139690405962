
import React from "react"
import { useOutletContext } from "react-router-dom";
import Minister from '../comp/Minister'
import FiguresFront from '../comp/FiguresFront'
import Notices from '../comp/Notices'

import MainGallery from '../comp/Gallery'
import Coursel from '../comp/Coursel'
import UrgentNotice from "../comp/UrgentNotice"
import HomeScriptComponent from "../utils/HomeScripts"
export default function Home(){
    const isLoaded= useOutletContext();
     return(
     <>
            <HomeScriptComponent isLoaded={isLoaded} />
            <Coursel />
            <section id="fontSize" className="wrapper body-wrapper ">
                <UrgentNotice />
                <div className="bg-wrapper top-bg-wrapper gray-bg padding-top-bott">
                <div className="container common-container four_content body-container top-body-container padding-top-bott2">
                    <Minister />
                    <div className="left-block"><FiguresFront /> </div>
                </div>
                </div>
                <Notices />
                <MainGallery />
            </section>
     </>
     )    
}







