import React from "react"
import { Link } from "react-router-dom";

export default function Footer() {
      return(
         <><Course /><Foot /></>
      )
}

function Foot() {
     return (
            <footer className="wrapper footer-wrapper">
                <div className="footer-top-wrapper">
                    <div className="container common-container four_content footer-top-container">
                    <ul>
                        <li><Link to="webpolicies">Website Policies</Link></li>
                        <li><Link to="contactus">Contact Us</Link></li>
                        <li><Link to="contactus">Feedback</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="footer-bottom-wrapper">
                    <div className="container common-container four_content footer-bottom-container">
                    <div className="footer-content clearfix">
                        <div className="copyright-content"> Website Content Managed by <strong>PHE/I&#38;FC Department, Ladakh</strong> <span>Designed, Developed and Hosted by <a target="_blank" title="NIC, External Link that opens in a new window" href="http://www.nic.in/"><strong>National Informatics Centre</strong></a><strong> ( NIC )</strong></span> </div>
                        <div className="logo-cmf">
                            <a target="_blank" href="http://cmf.gov.in/" title="External link that opens in new tab"><img className="cmg1" alt="nic logo" src="/assets/images/cmf-logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
     )
}
function Course() {
    return (
            <section className="wrapper carousel-wrapper">
            <div className="container common-container four_content carousel-container">
            <div id="flexCarousel" className="flexslider carousel">
                <ul className="slides">
                    <li>
                        <a target="_blank" href="http://digitalindia.gov.in/" title="Digital India, External Link that opens in a new window"><img src="/assets/images/carousel/digital-india.png" alt="Digital India" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="http://www.makeinindia.com/" title="Make In India, External Link that opens in a new window"> <img src="/assets/images/carousel/makeinindia.png" alt="Make In India" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="http://india.gov.in/" title="National Portal of India, External Link that opens in a new window"><img src="/assets/images/carousel/india-gov.png" alt="National Portal of India" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="http://goidirectory.nic.in/" title="GOI Web Directory, External Link that opens in a new window"><img src="/assets/images/carousel/goidirectory.png" alt="GOI Web Directory" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://data.gov.in/" title="Data portal, External Link that opens in a new window"><img src="/assets/images/carousel/data-gov.png" alt="Data portal" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://mygov.in/" title="MyGov, External Link that opens in a new window"><img src="/assets/images/carousel/mygov.png" alt="MyGov Portal" /></a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
      
    )
}

