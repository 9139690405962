import React, { useEffect } from "react"

export default function Header() {
   function set_font_size(fontType){
      window["set_font_size"](fontType);
   }
    return (
      <header>
         <div className="region region-header-top">
            <div id="block-cmf-content-header-region-block" className="block block-cmf-content first last odd">
               <noscript className="no_scr">"JzavaScript is a standard programming language that is included to provide interactive features, Kindly enable Javascript in your browser. For details visit help page"
               </noscript>
               <div className="wrapper common-wrapper">
                  <div className="container common-container four_content top-header">
                     <div className="common-left clearfix">
                        <ul>
                           <li className="gov-india"><span className="responsive_go_hindi" lang="hi"><a target="_blank" href="https://ladakh.nic.in/" title="केंद्र शासित प्रदेश लद्दाख" role="link">केंद्र शासित प्रदेश लद्दाख</a></span> </li>
                           <li className="ministry"><span className="li_eng responsive_go_eng"><a target="_blank" href="https://ladakh.nic.in/" title="Union Territory of Ladakh" role="link">Union Territory of Ladakh</a></span></li>
                        </ul>
                     </div>
                     <div className="common-right clearfix">
                        <ul id="header-nav">
                           <li className="ico-skip cf"><a href="#skipCont" title="">Skip to main content</a>
                           </li>
                           <li className="ico-accessibility cf">
                              <a href="#" onClick={(e) => { e.preventDefault() }} id="toggleAccessibility" title="Accessibility Dropdown" role="link">
                              <img className="top" src="/assets/images/ico-accessibility.png" alt="Accessibility Dropdown" />
                              </a>
                              <ul className="visblityhidden">
                                 <li> <a onClick={()=>{set_font_size('increase')}} title="Increase font size" href={void(0)} role="link">A<sup>+</sup>
                                    </a>
                                 </li>
                                 <li> <a onClick={()=>{set_font_size()}} title="Reset font size" href={void(0)}  role="link">A<sup>&nbsp;</sup></a> </li>
                                 <li> <a onClick={()=>{set_font_size('decrease')}} title="Decrease font size" href={void(0)} role="link">A<sup>-</sup></a> </li>
                                 <li> <a href={void(0)} className="high-contrast dark" title="High Contrast" role="link">A</a> </li>
                                 <li> <a href={void(0)} className="high-contrast light displaynone" title="Normal Contrast" role="link">A</a> </li>
                              </ul>
                           </li>
                         <li className="ico-social cf">
                              <a href={void(0)} onClick={(e) => { e.preventDefault() }} id="toggleSocial" title="Social Medias">
                              <img className="top" src="/assets/images/ico-social.png" alt="Social Medias" /></a>
                             <ul>
                                 <li>
                                    <a target="_blank" title="External Link that opens in a new window" href="http://www.facebook.com/"><img alt="Facebook Page" src="/assets/images/ico-facebook.png" /></a>
                                 </li>
                                 <li>
                                    <a target="_blank" title="External Link that opens in a new window" href="http://www.twitter.com/"><img alt="Twitter Page" src="/assets/images/ico-twitter.png" /></a>
                                 </li>
                                 <li>
                                    <a target="_blank" title="External Link that opens in a new window" href="http://www.youtube.com/"><img alt="youtube Page" src="/assets/images/ico-youtube.png" /></a>
                                 </li>
                              </ul>
                           </li>
                           <li className="ico-sitemap cf">
                              <a href="" title="Sitemap">
                              <img className="top" src="/assets/images/ico-sitemap.png" alt="Sitemap" /></a>
                           </li>
                           <li className="hindi cmf_lan d-hide">
                              <label className="de-lag">
                                 <span>Language</span>
                                 <select title="Select language">
                                    <option>English</option>
                                 </select>
                              </label>
                           </li>
                           <li className="hindi cmf_lan m-hide">
                              <a href={void(0)} title="Select Language">Language</a>
                              <ul>
                                 <li><a target="_blank" href="" lang="hi" className="alink" title="Click here for हिन्दी version.">हिन्दी</a></li>
                              </ul>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <p id="scroll" className="displaynone"><span></span></p>
         </div>
         {/*--Top-Header Section end */}
         <section className="wrapper header-wrapper">
            <div className="container common-container four_content header-container">
               <h1 className="logo">
                  <a href="/" title="Home" rel="home" className="header__logo" id="logo">
                     <img className="national_emblem" src="/assets/images/emblem-dark.png" alt="national emblem" />
                     <p> </p> 
                     <span> PHE/I&#38;FC Department </span>
                     <span> Ladakh </span>
                  </a>
               </h1>
               <div className="header-right clearfix">
                  <div className="right-content clearfix">
                     <div className="float-element">                           
                        <a className="sw-logo" target="_blank" href="https://swachhbharat.mygov.in/" title="Swachh Bharat, External link that open in a new windows"><img className="jjmlogo" src="/assets/images/jjm.png" alt="Swachh Bharat" /></a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        {/* <!--/.header-wrapper-->*/}
        
      </header>
      
    )
}
