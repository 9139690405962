import React, { useState,useEffect}  from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function Minister() {
   const [ details,setDetails] = useState({minister:[],dataisLoaded:0});
  
   useEffect(() =>{
           fetch(
               process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=MinisterData.image`,     
                 {
                   method: 'GET',
                   headers: {
                     Authorization: process.env.REACT_APP_API_KEY,
                     Accept: 'application/json'
                   },
                   credentials: "include",
                 }
             )
             .then((res) => res.json())
             .then((json) => {
               setDetails({
                   ...details,minister:json,dataisLoaded:1
               });
             })
             .catch((error) => {
               setDetails({
                 ...details,dataisLoaded:-1
             });
             });
           
     }, []);
 
     if (details.dataisLoaded===0) return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <CircularProgress />
       </Box>
     )
     else if (details.dataisLoaded===-1)  return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <Alert severity="error">
         <AlertTitle>Connection Error</AlertTitle>
              Could not connect to Server, kindly refresh
         </Alert>
       </Box>
     )
 
     else {
    
     const ministers=details.minister.data.attributes.MinisterData.map((mini)=>{
    
      let dfb,dtw;
      if (mini.fblink==null) {
         dfb=<></>;
      } else {
         dfb = <a target="_blank" href={mini.fblink} rel="noopener noreferrer" title="External link open in new tab ,facebbok link"><span style={{display: "none"}}>Facebook</span><i className="fa fa-facebook-square" aria-hidden="true"></i></a> ;
       }
       if (mini.twitterlink==null) {
         dtw=<></>;
      } else {
         dtw = <a target="_blank" href={mini.twitterlink} rel="noopener noreferrer" title="External link open in new tab,twitter link"><span style={{display: "none"}}>Twitter</span><i className="fa fa-twitter" aria-hidden="true"></i></a>;
       }  
      return(
            <div className="minister-sub1" key={mini.id}>
               <div className="minister-image"><img src={process.env.REACT_APP_BACK_SERVER+mini.image.data[0].attributes.url} className="minister-img" alt="minister" /></div>
               <div className="min-info">
                  <h4>{mini.Name}</h4>                    
                  <h3>{mini.Profile}</h3>
                   <div className="view-footer">       
                   { dfb }{ dtw }
                  </div> 
               </div>
            </div>
      )})
      return(
         <div className="minister clearfix">
         <div className="minister-box clearfix">
            {ministers}
         </div>
      </div>
      )
   }
}

