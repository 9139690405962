import React, { useState,useEffect}  from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function Coursel() {
   const [ details,setDetails] = useState({slideImage:[],dataisLoaded:0});
  
   useEffect(() =>{
           fetch(
                 process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=*`,
                 {
                   method: 'GET',
                   headers: {
                     Authorization: process.env.REACT_APP_API_KEY,
                     Accept: 'application/json'
                   },
                   credentials: "include",
                 }
             )
             .then((res) => res.json())
             .then((json) => {
               setDetails({
                   ...details,slideImage:json,dataisLoaded:1
               });
             })
             .catch((error) => {
               setDetails({
                 ...details,dataisLoaded:-1
             });
             });
     }, []);

     if (details.dataisLoaded===0) return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <CircularProgress />
       </Box>
     )
     else if (details.dataisLoaded===-1)  return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <Alert severity="error">
         <AlertTitle>Connection Error</AlertTitle>
              Could not connect to Server, kindly refresh
         </Alert>
       </Box>
     )
 
     else return(
     
         <section className="wrapper banner-wrapper">
         <div id="flexSlider" className="flexslider">
            <ul className="slides"> 
              {details.slideImage.data.attributes.Slider.data.map((img)=>
              <li key={img.id}> <img src={process.env.REACT_APP_BACK_SERVER+img.attributes.url} alt={img.attributes.alternativeText} /></li>
              )}
            </ul>
         </div>
      </section>
      )
}
