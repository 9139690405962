import React  from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import appRouter from './AppRouter'

import {RouterProvider,BrowserRouter} from "react-router-dom";

import reportWebVitals from './reportWebVitals';



class App extends React.Component {
  render() {
    return (
        <RouterProvider router={appRouter} />
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
