import { Typography } from '@mui/material'
import React from 'react'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';


const PageNotFound = () => {
    return (
        <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
        sx={{
          textAlign:'center'
        }}
      >
                <Paper elevation={3}>
                    <Box p={20} sx={{ backgroundColor: '#FAFAFA', color:"#BB3333" }}>
                    <Typography variant="h3" component="h1">404</Typography>
                    <Typography variant="h5" component="h2">This page could not be found</Typography>
                    </Box>
                </Paper>
        </Box>
    )
}

export default PageNotFound