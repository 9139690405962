
import { useEffect } from 'react';



const HomeScriptComponent = ({isLoaded}) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", "/theme/js/custom2.js");
    script.async = true;
    if(isLoaded)document.body.appendChild(script);
      return () => {
        if(isLoaded)document.body.removeChild(script);
        }
  }, [isLoaded]);

  }

  export default HomeScriptComponent