import React from 'react'
import { Routes,Route,createBrowserRouter, } from 'react-router-dom'

import Home from './pages/Home.js'
import SROsNotifications from './pages/SROsNotifications.js'
import PageNotFound from './pages/PageNotFound.js'
import Layout from './Layout.js'
import CircularsOrders from './pages/CircularsOrders.js'
import Tenders from './pages/Tenders.js'
import Gallery from './pages/Gallery.js'
import ContactUs from './pages/ContactUs.js'
import Profile from './pages/Profile.js'
import RTI from './pages/RTI.js'
import WebsitePolicies from './pages/WebsitePolicies.js'
import GallerySubPage from './pages/GallerySubPage.js'

const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
            path: "profile",
            element: <Profile />,
        },
        {
            path: "sros-notifications",
            element: <SROsNotifications />,
        },
        {
            path: "circulars-orders",
            element: <CircularsOrders />,
        },
        {
            path: "tenders",
            element: <Tenders />,
        },
        {
            path: "rti",
            element: <RTI />,
        },
        {
            path: "contactus",
            element: <ContactUs />,
        },
        {
            path: "gallery",
            element: <Gallery />,
        },
        {
            path: "gallery/:id",
            element: <GallerySubPage />,
        },
        {
            path: "webpolicies",
            element: <WebsitePolicies />,
        },
      ],
    },
  ]);

export default appRouter

