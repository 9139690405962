import React  from 'react';
import { Outlet } from "react-router-dom";
import Header from './comp/Header';
import NavBar from './comp/NavBar';
import Footer from './comp/Footer';
import ScriptComponent from './utils/Scripts.js';


const Layout = () => {
  const[isLoaded, setLoaded] = React.useState(false)   
  return (
    <><ScriptComponent setLoaded={setLoaded}/>
      <Header />
      <NavBar />
      <div className="wrapper" id="skipCont"></div>
      <Outlet context={isLoaded}/>
      <Footer />
    </>
   
  )
};

export default Layout;