import React, { useState,useEffect}  from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


export default function Profile(){


  const [ details,setDetails] = useState({whoswho:[],dataisLoaded:0});
  
  useEffect(() =>{
          fetch(
                process.env.REACT_APP_BACK_SERVER+`/api/directories?sort[0]=publishedAt%3Adesc`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                    Accept: 'application/json'
                  },
                  credentials: "include",
                }
            )
            .then((res) => res.json())
            .then((json) => {
              setDetails({
                  ...details,whoswho:json,dataisLoaded:1
              });
            })
            .catch((error) => {
              setDetails({
                ...details,dataisLoaded:-1
            });
            });
    }, []);
   
    if (details.dataisLoaded===0) return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )
    else if (details.dataisLoaded===-1)  return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <Alert severity="error">
        <AlertTitle>Connection Error</AlertTitle>
             Could not connect to Server, kindly refresh
        </Alert>
      </Box>
    )

    else return(
    <TableContainer >
      <Table sx={{ minWidth: 650, maxWidth: 900,margin:"50px auto", }} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell colSpan={4} sx={{textAlign:"center", color:"white"}} ><Typography  variant="h5" component="h1">Directory</Typography></TableCell>
          </TableRow>
          <TableRow >
            <TableCell  sx={{textAlign:"center", color:"white"}} >S. No</TableCell>
            <TableCell  sx={{textAlign:"center", color:"white"}} >Office</TableCell>
            <TableCell  sx={{textAlign:"center", color:"white"}} >Designation</TableCell>
            <TableCell  sx={{textAlign:"center", color:"white"}} >Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {details.whoswho.data.map((notice, index) => (
            <TableRow
              key={notice.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{index+1}</TableCell>
              <TableCell align="left">{notice.attributes.Office}</TableCell>
              <TableCell align="left">{notice.attributes.Designation}</TableCell>
              <TableCell align="left">{notice.attributes.Name}</TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    ) 
}


