import React, { useState,useEffect}  from "react"

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';

export default function UrgentNotice() {
      const [ details,setDetails] = useState({slideImage:[],dataisLoaded:0});
  let mini =[]
   useEffect(() =>{
           fetch(
                 process.env.REACT_APP_BACK_SERVER+`/api/front-gallery?sort[0]=publishedAt%3Adesc&populate=*`,
                 {
                   method: 'GET',
                   headers: {
                     Authorization: process.env.REACT_APP_API_KEY,
                     Accept: 'application/json'
                   },
                   credentials: "include",
                 }
             )
             .then((res) => res.json())
             .then((json) => {
               setDetails({
                   ...details,slideImage:json,dataisLoaded:1
               });
             })
             .catch((error) => {
               setDetails({
                 ...details,dataisLoaded:-1
             });
             });
     }, []);

     if (details.dataisLoaded===0) return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <CircularProgress />
       </Box>
     )
     else if (details.dataisLoaded===-1)  return(
       <Box 
       display="flex"
       justifyContent="center"
       alignItems="center"
       minHeight="60vh"
       >
         <Alert severity="error">
         <AlertTitle>Connection Error</AlertTitle>
              Could not connect to Server, kindly refresh
         </Alert>
       </Box>
     )
 
     else {
        {mini=details.slideImage.data.attributes.Notification}
        return(
        <section className="wrapper news-section">
            <div className="container common-container four_content carousel-container">
            <div id="flexCarouse2" className="news-section2">
                <div className="notification">
                </div>
                <div className="notification-mid">
                    <ul className="slides">
                        <li key={mini.id}>
                            <span>
                                <h3><a href={mini.UrgentNoticeLink} rel="noopener noreferrer" target="_blank">{mini.UrgentNoticeText} </a></h3>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
        </section>
      )
}}

