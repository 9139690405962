import React from "react"
import { Link } from "react-router-dom";
export default function NavBar(){
    return (
        
         <section className="wrapper megamenu-wraper">
           <div className="container common-container four_content">
              <p className="showhide"><em></em><em></em><em></em></p>
              <nav className="main-menu clearfix" id="main_menu">
                 <ul className="nav-menu">
                    <li className="nav-item"> 
                        <a href="/" className="home">
                             <span className="displaynone">home</span>
                             <i className="fa fa-home"></i>
                       </a> 
                    </li>
                    <li className="nav-item">
                       <a href="foo" onClick={(e) => {e.preventDefault()}}>About Us</a>
                       <div className="sub-nav">
                          <ul className="sub-nav-group">
                             <li><Link to="profile">Profile </Link></li>
                          </ul>
                       </div>
                    </li>
                    <li className="nav-item">
                       <a href="foo" onClick={(e) => {e.preventDefault()}}>  Notices </a>
                       <div className="sub-nav">
                          <ul className="sub-nav-group">
                             <li><Link to="sros-notifications">SROs &#47; Notifications</Link></li>
                             <li><Link to="circulars-orders">Circulars &#47; Orders</Link></li>
                          </ul>
                       </div>
                    </li>
                    <li className="nav-item"><Link to="tenders">Tenders</Link></li>
                    <li className="nav-item"><Link to="rti">RTI</Link></li>
                    <li className="nav-item"><a href="https://jaljeevanladakh.in/" target="_blank">Grievance</a></li>
                    <li className="nav-item"><Link to="gallery">Gallery</Link></li>
                    <li className="nav-item"><Link to="contactus">Contact Us</Link></li>
                 </ul>
              </nav>
              <nav className="main-menu clearfix" id="overflow_menu">
                 <ul className="nav-menu clearfix"></ul>
              </nav>
           </div>
        </section>

    )
}