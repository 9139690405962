
import React, { useState,useEffect}  from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



export default function SROsNotifications(){
  const [ details,setDetails] = useState({notificationsSROs:[],dataisLoaded:0});
  
  useEffect(() =>{
          fetch(
                process.env.REACT_APP_BACK_SERVER+`/api/documents/?filters[document_types][name][$eq][0]=Notification&filters[document_types][name][$eq][1]=SRO&sort[0]=publishedAt%3Adesc&populate=file`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                    Accept: 'application/json'
                  },
                  credentials: "include",
                }
            )
            .then((res) => res.json())
            .then((json) => {
              setDetails({
                  ...details,notificationsSROs:json,dataisLoaded:1
              });
            })
            .catch((error) => {
              setDetails({
                ...details,dataisLoaded:-1
            });
            });
    }, []);

    if (details.dataisLoaded===0) return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )
    else if (details.dataisLoaded===-1)  return(
      <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      >
        <Alert severity="error">
        <AlertTitle>Connection Error</AlertTitle>
             Could not connect to Server, kindly refresh
        </Alert>
      </Box>
    )

    else return(
    <TableContainer >
      <Table sx={{ minWidth: 650, maxWidth: 900,margin:"50px auto", }} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell colSpan={2} sx={{textAlign:"center", color:"white"}} ><Typography  variant="h5" component="h1">SROs &#47; Notifications</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        {details.notificationsSROs.data.map((notice, index) => (
            <TableRow
              key={notice.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
              {notice.attributes.title}
              </TableCell>
              <TableCell align="left">
              <a href={process.env.REACT_APP_BACK_SERVER+notice.attributes.file.data.attributes.url} target="_blank"><i className="fa fa-file-pdf-o" aria-hidden="true"></i>View/Download</a>
              </TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    ) 
}


